.owl-stage-outer {
  padding-bottom: 40px;

  .owl-item {
    &:first-of-type {
      margin-left: -50px;
      @include medium-up {
        margin-left: 0;
      }

      @media only screen and (max-width: 375px) {
        margin-left: 0;
      }
    }
  }
}

.owl-dots {
  position: relative;
  display: flex;
  justify-content: center;
  top: -25px;

  button.owl-dot {
    margin: 0 7px;
    padding: 0 7px;
    background: $slider-pagers;
    color: $slider-pagers;
    text-decoration: none;
    font-size: 18px;
    border-radius: 50px;
    cursor: pointer;
    opacity: .4;
    width: 20px;
    height: 20px;
    top: 10px;

    &.active {
      background: white;
      color: white;
      opacity: 1;
    }
  }
}

.owl-nav {
  position: relative;
  z-index: 999;
  padding: 25px 0px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0;

  .owl-prev,
  .owl-next {
    color: $slider-buttons;
    cursor: pointer;

    &:before {
      font-family: 'Font Awesome 5 Free';
      font-size: 48px;
      color: white;
      opacity: .15;
      font-weight: 900;
      @include medium-up {
        font-size: 72px;
      }
    }

    &:hover,
    &:focus {
      &:before {
        opacity: 1;
      }
    }
  }

  .owl-prev {
    &:before {
      content: '\f053';
    }
  }

  .owl-next {
    &:before {
      content: '\f054';
    }
  }
}

.owl-carousel:focus {
  outline: 0;
}