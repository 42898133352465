/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block;
  }
}

@media (min-width: 991px) {
  .show-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 990px) {
  .show-medium-only {
    display: none;
  }
}
@media (min-width: 1024px) {
  .show-medium-only {
    display: none;
  }
}

.show-large-only {
  display: none;
}
@media (min-width: 1024px) and (max-width: 1340px) {
  .show-large-only {
    display: block;
  }
}
@media (min-width: 1341px) {
  .show-large-only {
    display: none;
  }
}

.show-xlarge-only {
  display: none;
}
@media (min-width: 1341px) and (max-width: 1399px) {
  .show-xlarge-only {
    display: block;
  }
}
@media (min-width: 1400px) {
  .show-xlarge-only {
    display: none;
  }
}

.show-xxlarge-only {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-only {
    display: none;
  }
}

.show-medium-up {
  display: none;
}
@media (min-width: 991px) {
  .show-medium-up {
    display: block;
  }
}

.show-large-up {
  display: none;
}
@media (min-width: 1024px) {
  .show-large-up {
    display: block;
  }
}

.show-xlarge-up {
  display: none;
}
@media (min-width: 1341px) {
  .show-xlarge-up {
    display: block;
  }
}

.show-xxlarge-up {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 990px) {
  .hide-small-only {
    display: none;
  }
}

@media (min-width: 991px) and (max-width: 1023px) {
  .hide-medium-only {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1340px) {
  .hide-large-only {
    display: none;
  }
}

@media (min-width: 1341px) and (max-width: 1399px) {
  .hide-xlarge-only {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-only {
    display: none;
  }
}

@media (min-width: 991px) {
  .hide-medium-up {
    display: none;
  }
}

@media (min-width: 1024px) {
  .hide-large-up {
    display: none;
  }
}

@media (min-width: 1341px) {
  .hide-xlarge-up {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-up {
    display: none;
  }
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

section.homepage-callouts {
  display: block;
}
section.homepage-callouts .call-outs .call-out {
  display: inline-block;
  height: 100%;
  min-height: 450px;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: -4px;
}
section.homepage-callouts .call-outs .call-out .box-image,
section.homepage-callouts .call-outs .call-out .box-content {
  width: 100%;
}
@media (min-width: 991px) {
  section.homepage-callouts .call-outs .call-out .box-image,
section.homepage-callouts .call-outs .call-out .box-content {
    width: 50%;
  }
}
section.homepage-callouts .call-outs .call-out .box-image {
  background-size: cover;
  height: 100%;
  min-height: 270px;
  position: relative;
}
@media (min-width: 991px) {
  section.homepage-callouts .call-outs .call-out .box-image {
    min-height: 450px;
    width: 50%;
    position: absolute;
    left: 0px;
    height: 100%;
  }
}
section.homepage-callouts .call-outs .call-out .box-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media all and (-ms-high-contrast: none) {
  section.homepage-callouts .call-outs .call-out .box-image *::-ms-backdrop,
section.homepage-callouts .call-outs .call-out .box-image img {
    height: auto;
  }
}
section.homepage-callouts .call-outs .call-out .box-content {
  padding: 25px 20px 35px;
  text-align: center;
}
@media (min-width: 991px) {
  section.homepage-callouts .call-outs .call-out .box-content {
    text-align: left;
    padding: 100px 125px;
    position: relative;
    float: right;
  }
}
section.homepage-callouts .call-outs .call-out .box-content h2 {
  color: #037a8b;
  font-weight: 700;
  font-size: 20px;
  margin-top: 0;
}
section.homepage-callouts .call-outs .call-out .box-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@media (min-width: 991px) {
  section.homepage-callouts .call-outs .call-out:nth-of-type(even) .box-image {
    right: 0;
    left: auto;
  }
}
@media (min-width: 991px) {
  section.homepage-callouts .call-outs .call-out:nth-of-type(even) .box-content {
    float: left;
  }
}

section.featured-news {
  padding: 110px 20px 160px;
  background-image: url("/themes/athena/assets/dist/images/featured-news.jpg");
  background-size: cover;
  position: relative;
  z-index: 0;
}
section.featured-news:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.3);
}
section.featured-news h2 {
  margin: 0 0 20px;
  color: #ffd79c;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
}
@media (min-width: 991px) {
  section.featured-news h2 {
    max-width: 1600px;
    margin: 0 auto 30px;
  }
}
section.featured-news .news-items {
  width: 100%;
  display: block;
}
@media (min-width: 991px) {
  section.featured-news .news-items {
    display: flex;
    max-width: 1600px;
    margin: 0 auto;
  }
}
section.featured-news .news-items .owl-stage {
  display: flex;
}
section.featured-news .news-items .news-item {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  vertical-align: top;
  background: white;
  margin: 15px 0;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  height: 100%;
}
section.featured-news .news-items .news-item:first-of-type {
  margin-left: 0;
}
section.featured-news .news-items .news-item:last-of-type {
  margin-right: 0;
}
section.featured-news .news-items .news-item .news-item-image {
  width: 100%;
  position: relative;
}
section.featured-news .news-items .news-item .news-item-info {
  padding: 30px;
}
section.featured-news .news-items .news-item .news-item-info h3 {
  margin: 0 0 10px;
  font-size: 28px;
  font-weight: 700;
}
section.featured-news .news-items .news-item .news-item-info h3 a {
  color: #037a8b;
  text-decoration: none;
  line-height: 24px;
  border: none;
}
section.featured-news .news-items .news-item .news-item-info h3 a:hover, section.featured-news .news-items .news-item .news-item-info h3 a:focus {
  color: #404040;
}
section.featured-news .news-items .news-item .news-item-info .news-item-date {
  font-size: 14px;
  font-weight: 700;
  color: #343434;
  letter-spacing: 1px;
}
section.featured-news .news-items .news-item .news-item-info .news-item-blurb {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #343434;
}
section.featured-news .news-items .news-item .news-item-info .news-item-read-more a {
  color: #037a8b;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
section.featured-news .news-items .news-item .news-item-info .news-item-read-more a:hover, section.featured-news .news-items .news-item .news-item-info .news-item-read-more a:focus {
  color: #404040;
}
section.featured-news .view-all-news {
  padding: 10px 0 0;
  max-width: 1600px;
  margin: auto;
}
section.featured-news .view-all-news a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  font-size: 24px;
  border: none;
}
section.featured-news .view-all-news a:hover, section.featured-news .view-all-news a:focus {
  text-decoration: underline;
  color: white;
}

.owl-stage-outer {
  padding-bottom: 40px;
}
.owl-stage-outer .owl-item:first-of-type {
  margin-left: -50px;
}
@media (min-width: 991px) {
  .owl-stage-outer .owl-item:first-of-type {
    margin-left: 0;
  }
}
@media only screen and (max-width: 375px) {
  .owl-stage-outer .owl-item:first-of-type {
    margin-left: 0;
  }
}

.owl-dots {
  position: relative;
  display: flex;
  justify-content: center;
  top: -25px;
}
.owl-dots button.owl-dot {
  margin: 0 7px;
  padding: 0 7px;
  background: #ffffff;
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  border-radius: 50px;
  cursor: pointer;
  opacity: 0.4;
  width: 20px;
  height: 20px;
  top: 10px;
}
.owl-dots button.owl-dot.active {
  background: white;
  color: white;
  opacity: 1;
}

.owl-nav {
  position: relative;
  z-index: 999;
  padding: 25px 0px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0;
}
.owl-nav .owl-prev,
.owl-nav .owl-next {
  color: #ffffff;
  cursor: pointer;
}
.owl-nav .owl-prev:before,
.owl-nav .owl-next:before {
  font-family: "Font Awesome 5 Free";
  font-size: 48px;
  color: white;
  opacity: 0.15;
  font-weight: 900;
}
@media (min-width: 991px) {
  .owl-nav .owl-prev:before,
.owl-nav .owl-next:before {
    font-size: 72px;
  }
}
.owl-nav .owl-prev:hover:before, .owl-nav .owl-prev:focus:before,
.owl-nav .owl-next:hover:before,
.owl-nav .owl-next:focus:before {
  opacity: 1;
}
.owl-nav .owl-prev:before {
  content: "\f053";
}
.owl-nav .owl-next:before {
  content: "\f054";
}

.owl-carousel:focus {
  outline: 0;
}