section.homepage-callouts {
  display: block;

  .call-outs {
    .call-out {
      display: inline-block;
      height: 100%;
      min-height: 450px;
      overflow: hidden;
      position: relative;
      width: 100%;
      margin-bottom: -4px;

      .box-image,
      .box-content {
        width: 100%;
        @include medium-up {
          width: 50%;
        }
      }

      .box-image {
        background-size: cover;
        height: 100%;
        min-height: 270px;
        position: relative;
        @include medium-up {
          min-height: 450px;
          width: 50%;
          position: absolute;
          left: 0px;
          height: 100%;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        @media all and (-ms-high-contrast: none) {
          *::-ms-backdrop,
          img {
            height: auto;
          }
        }
      }

      .box-content {
        padding: 25px 20px 35px;
        text-align: center;
        @include medium-up {
          text-align: left;
          padding: 100px 125px;
          position: relative;
          float: right;
        }

        h2 {
          color: $primary-color;
          font-weight: $bold;
          font-size: 24px;
          margin-top: 0;
        }

        p {
          font-size: 18px;
          font-weight: $regular;
          line-height: 24px;
        }
      }

      &:nth-of-type(even) {
        .box-image {
          @include medium-up {
            right: 0;
            left: auto;
          }
        }

        .box-content {
          @include medium-up {
            float: left;
          }
        }
      }
    }
  }
}